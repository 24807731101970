import React from 'react'
import './App.css'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import colors from './styles/colors'

import mockupContent from './images/pongo-screen-shot.webp'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
  },
  typography: {
    fontFamily: ['"Righteous"', 'cursive'],
  }
})

const styles = {
  logo: {
    fontSize: 80,
    textShadow: `1px 1px 1px ${colors.darkGrey}`,
  },
  button: {
    margin: 30,
  },
  footerText: {
    color: colors.darkGrey,
  },
  footer: {
    bottom: '0',
    width: '100%',
    textAlign: 'center',
    backgroundColor: colors.black,
  },
  footerContent: {
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}

const emailUsText = `If you have any questions or just want to say hi, send an email to support@pongo.us`
const copyrightText = 'Copyright © 2020 Pongo Technologies. All rights reserved.'

function App(props) {
  const { classes } = props
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <AppBar
          position="static"
          color="primary"
        >
          <Toolbar>
            <Typography color="inherit" className={classes.logo}>
              Pongo
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="content">
          <h2 className="tag-line">Earn points for your purchasing data</h2>
          <div className="mockup-content-container">
            <img src={mockupContent} alt="mockup content" />
          </div>
          <Button
            className={classes.button}
            styles={{ padding: 20 }}
            variant="contained"
            color="secondary"
            href="https://testflight.apple.com/join/1F2oTpb1"
          >
            Download App
          </Button>
        </div>
        <footer className={classes.footer}>
          <div className={classes.footerContent}>
            <Typography className={classes.footerText}>
              {copyrightText}
            </Typography>
            <Typography className={classes.footerText}>
              {emailUsText}
            </Typography>
          </div>
        </footer>
      </div>
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(App)
